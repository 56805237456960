<template>
<section class="toprated">
    <div class="container">
        <div class="headline">
            <h2 class="headline__title">Top Rated <span>Package</span></h2>
            <span class="headline__meta">Best travel package with guaranteed quality</span>
        </div>
        <div class="row-zr" v-if="topselling">

            <div class="col xs-12 sm-12 md-6 order-2">
                <div class="packet x2">
                    <img :src="urlPath+'uploads/media/package/thumbnail/600450_'+topselling[0].featuredimage" alt="image description" class="stem">
                
                    <span class="packet__duration"><strong>{{topselling[0].tripdays}}</strong>Days</span>
                    <span class="packet__price" v-if="topselling[0].discountedprice>0">USD {{topselling[0].discountedprice}}</span>
                    <span class="packet__price" v-else>USD {{topselling[0].regularprice}}</span>
                    <div class="packet__box">
                        <h2 class="packet__box-title">{{topselling[0].title}}</h2>
                        <router-link :to="{name:'Packagedetail', params:{slug:topselling[0].slug,title:topselling[0].title}}" class="btn btn-default-invert">View Details</router-link>

                    </div>
                </div>
            </div>
            <div class="col xs-12 sm-6 md-3 order-1">
                <div class="packet">
                    <img :src="urlPath+'uploads/media/package/thumbnail/326245_'+topselling[1].featuredimage" alt="image description" class="stem">
                    <span class="packet__duration"><strong>{{topselling[1].tripdays}}</strong>Days</span>
                    <span class="packet__price" v-if="topselling[1].discountedprice>0">USD {{topselling[1].discountedprice}}</span>
                    <span class="packet__price" v-else-if="topselling[1].regularprice">USD {{topselling[1].regularprice}}</span>
                    <div class="packet__box">
                        <h2 class="packet__box-title">{{topselling[1].title}}</h2>
                        <router-link :to="{name:'Packagedetail', params:{slug:topselling[1].slug,title:topselling[1].title}}" class="btn btn-default-invert">View Details</router-link>
                    </div>
                </div>
                <div class="packet">
                    <img :src="urlPath+'uploads/media/package/thumbnail/326245_'+topselling[2].featuredimage" alt="image description" class="stem">
                    <span class="packet__duration"><strong>{{topselling[2].tripdays}}</strong>Days</span>
                    <span class="packet__price" v-if="topselling[2].discountedprice>0">USD {{topselling[2].discountedprice}}</span>
                    <span class="packet__price" v-else-if="topselling[2].regularprice">USD {{topselling[2].regularprice}}</span>
                    <div class="packet__box">
                        <h2 class="packet__box-title">{{topselling[2].title}}</h2>
                        <router-link :to="{name:'Packagedetail', params:{slug:topselling[2].slug,title:topselling[2].title}}" class="btn btn-default-invert">View Details</router-link>
                    </div>
                </div>
            </div>
            <div class="col xs-12 sm-6 md-3 order-3">
                <div class="packet">
                    <img :src="urlPath+'uploads/media/package/thumbnail/326245_'+topselling[3].featuredimage" alt="image description" class="stem">
                    <span class="packet__duration"><strong>{{topselling[3].tripdays}}</strong>Days</span>
                    <span class="packet__price" v-if="topselling[3].discountedprice>0">USD {{topselling[3].discountedprice}}</span>
                    <span class="packet__price" v-else-if="topselling[3].regularprice">USD {{topselling[3].regularprice}}</span>
                    <div class="packet__box">
                        <h2 class="packet__box-title">{{topselling[3].title}}</h2>
                        <router-link :to="{name:'Packagedetail', params:{slug:topselling[3].slug,title:topselling[3].title}}" class="btn btn-default-invert">View Details</router-link>
                    </div>
                </div>
                <div class="packet">
                     <img :src="urlPath+'uploads/media/package/thumbnail/326245_'+topselling[4].featuredimage" alt="image description" class="stem">
                    <span class="packet__duration"><strong>{{topselling[4].tripdays}}</strong>Days</span>
                    <span class="packet__price" v-if="topselling[4].discountedprice>0">USD {{topselling[4].discountedprice}}</span>
                    <span class="packet__price" v-else-if="topselling[4].regularprice">USD {{topselling[4].regularprice}}</span>
                    <div class="packet__box">
                        <h2 class="packet__box-title">{{topselling[4].title}}</h2>
                        <router-link :to="{name:'Packagedetail', params:{slug:topselling[4].slug,title:topselling[4].title}}" class="btn btn-default-invert">View Details</router-link>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Toprated',
    data() {
        return {
            topselling: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    mounted() {
        this.topsellingPackage();
    },
    methods: {
        topsellingPackage() {
            axios.get(this.apiPath+'topsellingpackage')
                .then((response) => {
                    this.topselling = response.data;
                })
        }
    }
}
</script>
